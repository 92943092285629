.abhishar-tabs-container {
  background-color: var(--primary-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding-top: 5rem;
  padding-bottom: 1rem;
}

.abhishar-tab {
  padding: 0rem 5rem;
}

.abhisharpage-section {
  background-color: var(--primary-bg-color);
  display: flex;
  justify-content: space-between;
  padding: 0rem 5rem;
  padding-top: 0rem;
  font-size: 1.55rem;
}

.abhisharpage-content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 47rem;
}

.abhisharpage-headings {
  padding: 0rem 2rem;
  font-size: 3rem;
}

.abhisharpage-text {
  padding: 2rem;
}

.abhisharpage-image {
  padding: 2rem;
}

.abhisharpage-image img {
  height: 38rem;
}

.abhisharpage-card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: var(--primary-bg-color);
  padding: 1rem 0rem;
}

.abhishar-no-result {
  opacity: 0.3;
  font-size: 1.5rem;
  padding: 5rem 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.abhishar-no-result img {
  height: 30vh;
}

@media screen and (max-width: 1300px) {
  .abhisharpage-section {
    font-size: 1.3rem;
    padding: 0rem 3.5rem;
  }

  .abhisharpage-headings {
    font-size: 2.7rem;
  }

  .abhisharpage-image img {
    height: 32rem;
  }
}

@media screen and (max-width: 1100px) {
  .abhisharpage-section {
    font-size: 1.2rem;
    padding: 0rem 2.75rem;
  }

  .abhisharpage-headings {
    font-size: 2.5rem;
  }

  .abhisharpage-image img {
    height: 28rem;
  }
}

@media screen and (max-width: 960px) {
  .abhisharpage-section {
    font-size: 1rem;
    padding: 0rem 2.5rem;
  }

  .abhisharpage-headings {
    font-size: 2rem;
  }

  .abhisharpage-image img {
    height: 22rem;
  }
}

@media screen and (max-width: 800px) {
  .abhisharpage-section {
    font-size: 1rem;
    padding: 0rem 2.5rem;
    flex-direction: column-reverse;
  }

  .abhisharpage-headings {
    font-size: 2rem;
    text-align: center;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .abhisharpage-text {
    text-align: center;
  }

  .abhisharpage-image {
    display: flex;
    justify-content: center;
  }

  .abhisharpage-image img {
    height: 22rem;
  }
}

@media screen and (max-width: 500px) {
  .abhisharpage-section {
    font-size: 0.9rem;
    padding: 0rem 1.2rem;
  }

  .abhisharpage-headings {
    font-size: 1.75rem;
  }

  .abhisharpage-image img {
    height: 20rem;
  }
}
