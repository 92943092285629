.footer {
  background-color: var(--primary-bg-color);
  padding: 1rem 5rem;
}

.footer-container {
  display: flex;
  font-size: 0.9rem;
  justify-content: space-between;
}

.footer-address-container {
  display: flex;
  align-items: center;
}

.footer-menu-container {
  display: flex;
}

.footer-address-image {
  padding-right: 2rem;
}

.footer-address-text {
  padding-right: 20rem;
}

.footer-menu {
  padding-right: 3.5rem;
}

.footer-menu-heading {
  padding-bottom: 1rem;
}

.footer-menu-items {
  display: flex;
  flex-direction: column;
}

.footer-menu-items a {
  padding: 0.2rem 0rem;
}

.footer-socialmedia-icons {
  display: flex;
  justify-content: flex-end;
  padding-top: 2.5rem;
}

.footer-socialmedia-icons i {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media screen and (max-width: 1200px) {
  .footer-address-text {
    padding-right: 14rem;
  }
}

@media screen and (max-width: 1100px) {
  .footer-address-text {
    padding-right: 9rem;
  }

  .footer-container {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 950px) {
  .footer-address-text {
    padding-right: 7rem;
  }

  .footer-container {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 850px) {
  .footer {
    padding: 1rem 1rem;
  }
  .footer-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .footer-menu-container {
    padding-bottom: 2rem;
  }

  .footer-address-text {
    padding-right: 0rem;
  }
  .footer-menu:nth-child(3) {
    padding-right: 0rem;
  }
}
