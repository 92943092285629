:root {
  --primary-bg-color: #000000;
  --secondary-bg-color: #2a2a2b;
  --primary-accent-color: #ffcd25;
  --secondary-accent-color: #d4b2e4;
  --primary-text-color: #ffffff;
  --secondary-text-color: #e9e8e8;
  --primary-font: 'Work Sans';
  --secondary-font: 'Mulish';
}

html {
  font-size: 16px;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ol,
ul,
li,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
footer,
header,
menu,
nav,
section,
audio,
video {
  margin: 0;
  padding: 0;
}

article,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

a {
  text-decoration: none;
  color: var(--secondary-text-color);
  transition: 0.4s;
}

a:hover {
  color: var(--primary-accent-color);
}

hr {
  display: block;
  height: 1px;
  border: 0px;
  border-top: 1px solid #000;
  margin: 0px;
  padding: 0px;
}

dl,
ol,
ul {
  list-style-type: none;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
  font-size: 100%;
  padding: 0;
  touch-action: manipulation;
  user-select: none;
  -webkit-appearance: none;
}

/* Common CSS */

.flex-centre {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-scroll {
  overflow: hidden;
}

.primary-font {
  font-family: var(--primary-font);
  color: var(--primary-text-color);
}

.secondary-font {
  color: var(--secondary-text-color);
  font-family: var(--secondary-font);
}

.heading-font {
  color: var(--primary-accent-color);
  font-family: var(--primary-font);
  font-weight: 1000;
}
