.customHeaderContainer {
  position: relative;
  background-color: var(--primary-bg-color);
  border: none;
  height: 37.5rem;
}

.customHeader__image img {
  width: 90vw;
  object-fit: cover;
  opacity: 50%;
  border-radius: 1.875rem;
  height: 37.5rem;
}
.customHeader__text {
  color: var(--primary-accent-color);
  position: absolute;
  font-family: var(--primary-font);
  font-size: 7rem;
  font-weight: bold;
}

@media screen and (max-width: 1050px) {
  .customHeaderContainer {
    height: 30rem;
  }
  .customHeader__image img {
    height: 30rem;
  }
  .customHeader__text {
    font-size: 6rem;
  }
}

@media screen and (max-width: 650px) {
  .customHeaderContainer {
    height: 24rem;
  }
  .customHeader__image img {
    height: 24rem;
  }
  .customHeader__text {
    font-size: 5rem;
  }
}

@media screen and (max-width: 490px) {
  .customHeaderContainer {
    height: 16rem;
  }
  .customHeader__image img {
    height: 16rem;
  }
  .customHeader__text {
    font-size: 3rem;
  }
}
