.navbar-container {
  position: sticky;
  top: 0;
  z-index: 20;
}

.navbar-opaque {
  background-color: var(--primary-bg-color);
}

.navbar-backdrop {
  background-color: rgb(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 20px 4px #000000;
}

.navbar {
  height: 4.5rem;
  font-size: 1.4rem;
}

.nav-container {
  width: 100%;
  height: 4.5rem;
  /* max-width: 93.75rem; */
  max-width: 110rem;
}

.navbar-main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.nav-logo {
  align-items: center;
  margin-left: 1.25rem;
  flex-grow: 1;
  transform: translate(0, 0.4rem);
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-item {
  padding: 0.5rem 1rem;
  height: 50%;
  border-bottom: 0.2rem solid transparent;
  line-height: 2.5rem;
  margin-right: 1rem;
}

.nav-item:after {
  content: '';
  display: block;
  height: 0.2rem;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: var(--primary-accent-color);
}

.nav-icon {
  display: none;
}

.navbar-active {
  color: var(--primary-accent-color);
  font-weight: 600;
}

@media screen and (max-width: 960px) {
  .nav-items-container {
    height: 100vh;
  }

  .navbar {
    height: 3rem;
  }

  .nav-container {
    height: 3rem;
  }

  .nav-logo {
    transform: translate(0, 0.7rem);
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 7rem;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.navbar-active {
    background: var(--primary-bg-color);
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .nav-item {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.5rem;
    cursor: pointer;
  }
}
