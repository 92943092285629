.contact-us-page {
  display: flex;
  justify-content: space-between;
  padding: 2rem 6rem;
  background-image: url('https://res.cloudinary.com/dzerj4bzd/image/upload/v1661771074/AASF%20Website/contact-us-bg_aiwv2p.png');
  background-size: cover;
}

.contact-us-page-right {
  padding-right: 2rem;
}

.contact-us-page-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.google-map-button a {
  font-size: 1.1rem;
  color: var(--primary-accent-color);
  background-color: var(--primary-bg-color);
  padding: 0.75rem 2rem;
  border-radius: 1rem;
  width: fit-content;
  cursor: pointer;
}

.google-map-button {
  transition: 0.5s;
}

.google-map-button:hover {
  transform: scale(1.01);
}

.contact-us-title {
  transform: translate(0, -15rem);
}

.contact-us-title-heading {
  font-size: 5rem;
  text-shadow: 0px 2px, 2px 0px, 2px 2px currentColor;
  padding: 1rem 0rem;
}

.contact-us-title-motto {
  font-size: 2.2rem;
  font-weight: 700;
  padding: 1rem 0rem;
}

@media screen and (max-width: 1300px) {
  .google-map-button a {
    font-size: 0.9rem;
  }

  .contact-us-title-heading {
    font-size: 4rem;
    text-shadow: 0px 1px, 1px 0px, 1px 1px currentColor;
    padding: 0.7rem 0rem;
  }

  .contact-us-title-motto {
    font-size: 1.8rem;
    padding: 0.7rem 0rem;
  }
}

@media screen and (max-width: 960px) {
  .contact-us-page {
    flex-direction: column;
    align-items: center;
  }

  .contact-us-title {
    transform: translate(0, 0);
  }

  .contact-us-page-right {
    padding-right: 0rem;
  }

  .google-map-button {
    padding: 1rem 0rem;
  }

  .contact-us-title-heading {
    padding: 1rem 0rem;
  }

  .contact-us-title-motto {
    padding: 1rem 0rem;
  }

  .contact-us-page-right {
    padding: 1rem 0rem;
  }
}

@media screen and (max-width: 600px) {
  .contact-us-title-heading {
    padding: 1rem 0rem;
    font-size: 3rem;
  }

  .contact-us-title-motto {
    padding: 0.5rem 0rem;
    font-size: 1.2rem;
  }

  .contact-us-page-right {
    padding: 1rem 0rem;
  }
}

@media screen and (max-width: 500px) {
  .contact-us-title-heading {
    padding: 1rem 0rem;
    font-size: 2rem;
  }

  .contact-us-title-motto {
    padding: 0.3rem 0rem;
    font-size: 1rem;
  }

  .contact-us-page-right {
    padding: 1rem 0rem;
  }
}
